/* PrecisionTestingAndScanMode.css */
.container {
  padding: 20px;
  font-family: 'Open Sans, Arial, sans-serif';
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background-color: white;
}

.sectionHeader {
  font-size: 1.5rem;
  color: #0A3D62;
  text-align: center;
  margin: 20px 0;
}

.subHeader {
  font-size: 1.2rem;
  color: #0A3D62;
  text-align: center;
  margin: 20px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
}

.field {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 0;
}

.label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #B0BEC5;
  text-align: center;
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioLabel {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.radio {
  margin-right: 5px;
}

.highlight {
  background-color: #FFEB3B;
  font-weight: bold;
}

.divider {
  margin: 10px 0;
  border-top: 1px solid #E0E0E0;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    padding: 0;
    flex: 1;
  }
  .row {
    flex-direction: column;
  }
  .field {
    margin: 0 10px;
  }
}