body {
    font-family: 'Open Sans', Arial, sans-serif;
}

h1, h2, h3 {
    font-family: 'Roboto', Arial, sans-serif;
}

:root {
  --deep-blue: #0A3D62;
  --teal: #17A2B8;
  --cool-gray: #B0BEC5;
  --soft-white: #F5F5F5;
  --soft-beige: #F2E9DC;
  --medium-teal: #4BAFD1;
  --vibrant-orange: #FF6B35;
  --dark-charcoal: #2E3A45;
}

body {
  font-family: 'Open Sans', Arial, sans-serif;
  color: var(--dark-charcoal);
}

h1, h2, h3 {
  font-family: 'Roboto', Arial, sans-serif;
  color: var(--deep-blue);
}